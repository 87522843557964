<template>
  <div class="operation_box">
    <div style="background-color:white;padding:0 8px">
      <van-cell-group :border="false">
        <van-field v-model="bankName" label="开户银行:" readonly />
        <van-field v-model="bankAcount" label="银行账号:" placeholder="请输入银行账号">
          <template #button>
            <van-button size="small" @click="getAccount"></van-button>
          </template>
        </van-field>
      </van-cell-group>
      <van-divider dashed :style="{ borderColor: 'black', padding: '0 16px',margin:0 }"></van-divider>
      <p>*暂不支持使用存折、社保卡、信用卡开通</p>
    </div>
    <van-button type="info" @click="nextStep">下一步</van-button>
  </div>
</template>
<script>
export default {
  data () {
    return {
      bankName: '中国农业银行',
      bankAcount: '',
    }
  },
  methods: {
    nextStep () {
      if (!this.bankAcount) {
        this.$dialog.confirm({
          title: '提示',
          message: '请输入银行账号',
          showCancelButton: false
        })
          .then(() => {
            // on confirm
          })
          .catch(() => {
            // on cancel
          })
      } else {
        this.$router.push('/ApplyBankAcountDetail')
      }
    },
    getAccount () {
      this.$dialog.alert({
        title: '提示',
        message: 'OCR接口已到期，请及时充值！',
        showCancelButton: false
      }).then(() => {
        // on close
      })
    }
  }
}
</script>
<style lang="less" scoped>
.operation_box {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgb(248, 248, 248);
  padding-top: 8px;
  /deep/ .van-field {
    display: flex;
    align-items: center;
    .van-button {
      width: 26px;
      color: #51b8fe;
      border: none;
      background: url('../../../assets/PayFee/phonesup.png') no-repeat center;
      background-size: 100%;
    }
  }
  p {
    font-size: 14px;
    padding: 0 0 20px;
  }
  & > .van-button {
    width: 80%;
    border-radius: 40px;
    margin: 20px 0;
    font-size: 20px;
  }
}
</style>